import { FeatureFlagKey, FEATURES } from '../appConstants';
import useTenant from './useTenant';

type ExtendedFeatureFlagKey = FeatureFlagKey | 'rulesRewardExpiryFlexibleSetup';
type ExtendedFeatureFlags = { [key in ExtendedFeatureFlagKey]?: boolean };

/**
 * Let's keep items in this dictionary sorted by key.
 *
 * It will help us to
 * - quicker find a desired item
 * - make more consistent naming for new items
 * - prevent merge conflicts when there are several pull requests
 * with new feature flags at the end of the list
 */
export const defaultFeatures = {
  [FEATURES.ALPHABET_VOUCHER_CODE.key]: true,
  [FEATURES.ALPHANUMERIC_VOUCHER_CODE.key]: true,
  [FEATURES.AUDIENCE_DEMOGRAPHICS_SELECTION.key]: true,
  // [FEATURES.AUTO_ENROL_USERS.key]: true,
  [FEATURES.BRANDS.key]: true,
  [FEATURES.BULK_ACTIONS_UPLOAD_USERS.key]: true,
  [FEATURES.CAMPAIGNS.key]: true,
  [FEATURES.CATALOGS.key]: true,
  [FEATURES.CATALOGUE_TAG_MERCHANT.key]: true,
  [FEATURES.CATALOGUE_TERMS_CONDITIONS.key]: true,
  [FEATURES.CUSTOM_FIELDS.key]: true,
  [FEATURES.CUSTOMERS_INITIAL_TAB.key]: true,
  [FEATURES.GAMES_VARIABLE_POINT.key]: true,
  [FEATURES.QUIZ_VARIABLE_POINT.key]: true,
  [FEATURES.HEXANUMERIC_VOUCHER_CODE.key]: true,
  [FEATURES.LABELS.key]: true,
  [FEATURES.LOYALTIES.key]: true,
  [FEATURES.LOYALTY_EXTEND_MEMBERSHIP.key]: true,
  [FEATURES.LOYALTY_POINTS_TRANSFER_LIMITS.key]: true,
  [FEATURES.LOYALTY_REVIEW_CYCLES.key]: true,
  [FEATURES.LOYALTY_RULE_BIRTHDAY_CHECKBOX.key]: true,
  [FEATURES.LOYALTY_RULE_TRANSACTION_PROPERTY.key]: true,
  [FEATURES.LOYALTY_RULE_TRANSACTION_TYPE.key]: true,
  [FEATURES.LOYALTY_UPLOAD_PHOTO.key]: true,
  [FEATURES.NUMERIC_VOUCHER_CODE.key]: true,
  [FEATURES.PENDO_CONFIGURATION.key]: true,
  [FEATURES.PRICE_IDENTIFIER.key]: true,
  [FEATURES.PRIVATE_REWARDS.key]: true,
  [FEATURES.QUIZ_CAMPAIGNS.key]: true,
  [FEATURES.REWARD_ACTIVATION_DATE.key]: true,
  [FEATURES.REWARD_CAMPAIGN.key]: true,
  [FEATURES.REWARD_COST.key]: true,
  [FEATURES.REWARD_LEGACY_AUDIENCE.key]: true,
  [FEATURES.REWARD_SELLING_PRICE_CASH.key]: true,
  [FEATURES.REWARD_TYPE_FILTER_TAB.key]: true,
  [FEATURES.REWARDS_DISPLAY_EVEN_WHEN.key]: true,
  [FEATURES.REWARDS_FORM_CATALOG_ASSIGNMENT.key]: true,
  [FEATURES.REWARDS_GIFTING.key]: true,
  [FEATURES.REWARDS_LIST_STOCK_FILTERS.key]: true,
  [FEATURES.REWARDS_REDEMPTION_COUNTDOWN.key]: true,
  [FEATURES.REWARDS_VALIDITY_PERIOD_END_OF_MONTH.key]: true,
  [FEATURES.REWARDS_BULK_UPLOAD.key]: true,
  [FEATURES.TRANSACTION_RULE_TRIGGER.key]: true,
  [FEATURES.VOUCHER_CODE_PREFIX.key]: true,
  [FEATURES.LANGUAGE_SELECTOR.key]: false
};

const useFeatureFlags = (): ExtendedFeatureFlags => {
  const { features = {} } = useTenant();
  return {
    ...features,
    rulesRewardExpiryFlexibleSetup: true
  };
};

export default useFeatureFlags;
